.header{
background-color: lightgrey;
padding:10px;
font-size:20px;

}

.header a{
    text-decoration: none;
    color:black;
}   

.Home{
    float:right;
    margin-right: 20px;
}