* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  .Recipe div h3{
    text-align: center;
  }
  /* The grid: Three equal columns that floats next to each other */
  .row{
      display:grid;
      grid-template-columns: repeat(3, 1fr);
  }
  .column {
    margin:10px;
    height:200px;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border: 3px solid black;
    border-radius: 6px;
    box-shadow: 0 3px 7px 2px gray;
  }
  .column:hover{
    transform: scale(1.05);
    box-shadow: none;
  }
  .column span{
    display: block;
    position:absolute;                  
    bottom:10px;                          
    left:0; 
    background-color: #e4e4e4;
    padding: 5px 10px;
    width: -webkit-fill-available;
    font-size: 20px;
    color: black;
  }
  .containerTab {
    padding: 20px;
    color: black;
    margin:10px;
    border:2px solid black;
    border-radius: 6px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Closable button inside the container tab */
  .closebtn {
    float: right;
    color: black;
    font-size: 35px;
    cursor: pointer;
  }
  .closebtn:hover{
    transform:scale(1.05);
  }
  .steps{
      background-color: transparent;
  }
  .containerTab h1{
    border-bottom: 2px solid #00000094;
  }
  .containerTab button{
      background-color: black;
      color:white;
      outline:none;
      border:2px solid black;
      border-radius :7px;
      margin: 10px 0px;
      font-size:15px;
      padding: 5px 7px;
      transition-duration: 0.2s;
      width:100px;
      cursor: pointer;
  }
  .containerTab button:hover{
    background-color: white;
    color:black;
    transform: scaleX(1.05);

  }
  .containerTab div{
    display:flex;
    flex-wrap: wrap;
  }
  .containerTab div pre{
    margin: 5px 5px;
    border: 2px solid black;
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
  }
  .containerTab div pre span{
    font-weight: 800;
    padding: 0px;
    border:none;
  }
  

  @media (max-width:600px){
    .row{
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width:601px) and  (max-width:991px){
    .row{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width:1500px){
    .row{
      grid-template-columns: repeat(4, 1fr);
    }
  }